
  import { ValidationProvider } from 'vee-validate';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DatePicker from 'vue2-datepicker';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
  import 'vue2-datepicker/index.css';

  @Component({
    components: {
      FormInvalidMessage,
      DatePicker,
      ValidationProvider,
    },
    inheritAttrs: false,
  })

  export default class FormDatePicker extends Vue {
    @Prop({ default: true }) bails!: boolean;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) dateToMidnight!: boolean;
    @Prop({ default: true }) editable!: boolean;
    @Prop({ default: 'DD/MM/YYYY' }) format!: string;
    @Prop({ required: true }) label!: string;
    @Prop() description!: string;
    @Prop({ required: true }) name!: string;
    @Prop() placeholder: string | undefined;
    @Prop({ default: false }) range!: boolean;
    @Prop() rules!: string;
    @Prop({ default: 'date' }) type!: string;
    @Prop({ default: null }) value!: Date | null;
    @Prop({ default: null }) disabledDate!: Function | null;
    @Prop({ default: null }) defaultValue!: Date | null;

    get datePickerDynamicProps(): Object {
      const props: { [key: string]: any } = {};
      if (this.disabledDate) {
        props.disabledDate = this.disabledDate;
      }
      if (this.defaultValue) {
        props.defaultValue = this.defaultValue;
      }
      return props;
    }

    updateDate(date: Date | null, type: string): void {
      let dateToEmit = date;
      if (date && this.dateToMidnight) {
        dateToEmit = new Date(date.getFullYear(), date.getMonth(), type === 'month' ? 0 : date.getDate());
      }
      this.$emit('input', dateToEmit);
    }
  }
